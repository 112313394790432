<template>
    <v-row>
        <v-col cols="5">
            <v-card elevation="4">
                <v-card-title>
                    {{ availableStores.length }} Negozi Disponibili
                </v-card-title>
                <v-text-field
                    v-model="availablesearch"
                    label="Punto Vendita"
                    append-icon="mdi-magnify"
                    class="mx-4"
                    filled
                ></v-text-field>

                <StoresDragList
                    :stores.sync="availableStores"
                    :search="availablesearch"
                    :readonly="readonly"
                    :height="'435'"
                    @currentSelection="availableChanged"
                ></StoresDragList>
            </v-card>
        </v-col>
        <v-col cols="2" align-self="center">
            <v-row>
                <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                        fab
                        icon
                        large
                        @click="removeAll"
                        :disabled="currentSelected.length === 0"
                    >
                        <v-icon x-large>mdi-rewind</v-icon></v-btn
                    >
                </v-col>
                <v-col
                    cols="12"
                    class="d-flex justify-center"
                    :disabled="currentAvailable.length === 0"
                >
                    <v-btn fab icon large @click="addAll">
                        <v-icon x-large>mdi-fast-forward</v-icon></v-btn
                    >
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                    <v-btn fab icon large @click="importStores">
                        <v-icon x-large>mdi-file-import-outline</v-icon></v-btn
                    >
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="5">
            <v-card elevation="4">
                <v-card-title>
                    {{ promoStores.length }} Negozi Applicati
                </v-card-title>
                <v-text-field
                    v-model="appliedsearch"
                    label="Punto Vendita"
                    append-icon="mdi-magnify"
                    class="mx-4"
                    filled
                ></v-text-field>

                <StoresDragList
                    :class="{ changed: changed }"
                    :stores.sync="promoStores"
                    :search="appliedsearch"
                    :readonly="readonly"
                    :height="'435'"
                    @currentSelection="appliedChanged"
                ></StoresDragList>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as storeActions from "../../store/actions/store";
import { wait } from '../../utils/utils'

export const StoresDragList = () => import("./PromoStoresDragList");

export default {
    props: {
        stores: {
            type: Array,
            required: true
        },
        readonly: {
            type: Boolean,
            required: true
        },
        changed: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: () => ({
        promoStores: [],
        availableStores: [],
        appliedsearch: "",
        availablesearch: "",
        mounted: false,
        currentAvailable: [],
        currentSelected: []
    }),

    components: {
        StoresDragList
    },

    watch: {
        promoStores: function(newVals, oldVals) {
            // console.log(this.mounted);
            if (this.mounted) {
                // console.log(newVals, oldVals);
                const stores = newVals.map(val => {
                    return val.IDCliente;
                });
                // console.log(stores)
                this.$emit("update:stores", stores);
            }
        },

        stores: function(newVal) {
            // console.log('stores modificati', newVal)
            this.convertStores();
        }
    },

    computed: {
        ...mapState({
            farmStores: state => state.storeModule.stores,
            loaded: state => state.storeModule.loaded
        })

        // dragOptions() {
        //     return {
        //         animation: 0,
        //         group: "description",
        //         disabled: !this.editable,
        //         ghostClass: "ghost"
        //     };
        // }
    },

    methods: {
        ...mapActions({
            loadStores: storeActions.STORE_GET_STORES
        }),

        async getStores(farmId) {
            try {
                if (!this.loaded) {
                    const state = await this.loadStores(farmId);
                }
                return this.farmStores;
            } catch (err) {
                console.error(err);
            }
        },

        availableChanged(available) {
            //console.log('siamo filtrati e siamo', available)
            this.currentAvailable = available;
        },

        appliedChanged(applied) {
            console.log('siamo filtrati e siamo', applied.length)
            this.currentSelected = applied;
        },

        addAll() {
            //console.log('premuto add all', this.currentAvailable.length)
            this.promoStores = [...this.promoStores, ...this.currentAvailable];
            const ids = this.currentAvailable.map(x => x.IDCliente);
            this.availableStores = this.availableStores.filter(store => {
                return !ids.includes(store.IDCliente);
            });
        },

        removeAll() {
            //console.log('premuto remove all')
            this.availableStores = [...this.availableStores, ...this.currentSelected];
            const ids = this.currentSelected.map(x => x.IDCliente);
            this.promoStores = this.promoStores.filter(store => {
                return !ids.includes(store.IDCliente)
            })
        },

        async convertStores() {
            this.mounted = false;
            const farmStores = await this.getStores(this.$route.params.id);

            this.promoStores = farmStores.filter(store => {
                return this.stores.includes(store.IDCliente);
            });
            this.availableStores = farmStores.filter(store => {
                return (
                    store.CodiceNazionaleCoopGLN.length > 0 &&
                    !this.stores.includes(store.IDCliente)
                );
            });
            setTimeout(() => {
                this.mounted = true;
            }, 0);
        },

        importStores() {
            this.$formdialog
                .open({
                    type: "",
                    title: "Importa Negozi",
                    data: { farmId: this.$route.params.id },
                    formName: "ImportStores",
                    fullScreen: true
                })
                .then(async response => {
                    this.$formdialog.showProgress(true);
                    const ids = this.promoStores.map(x => x.IDCliente);
                    const merged = [...new Set([...ids, ...response])]
                    const farmStores = await this.getStores(this.$route.params.id);

                    this.promoStores = farmStores.filter(store => {
                        return merged.includes(store.IDCliente);
                    });

                    this.availableStores = farmStores.filter(store => {
                        return (
                            store.CodiceNazionaleCoopGLN.length > 0 &&
                            !this.stores.includes(store.IDCliente)
                        );
                    });
                    await wait(500);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    this.$formdialog.showProgress(false);
                    this.$formdialog.destroy();
                });
        }
    },

    mounted() {
        this.$nextTick(async () => {
            await this.convertStores();
        });
    }
};
</script>

<style scoped></style>
